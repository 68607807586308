<template>
  <div class="banner-box">
    <div class="banner">
      <img src="@/assets/logo.jpg" />
      <!-- <svg
        viewBox="0 0 24 24"
        aria-hidden="true"
        class="
          r-jwli3a
          r-4qtqp9
          r-yyyyoo
          r-rxcuwo
          r-1777fci
          r-m327ed
          r-dnmrzs
          r-494qqr
          r-bnwqim
          r-1plcrui
          r-lrvibr
        "
      >
        <g>
          <path
            d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"
          ></path>
        </g>
      </svg> -->
    </div>
    <div class="main">
      <div class="content">
        <svg
          viewBox="0 0 24 24"
          aria-hidden="true"
          class="
            r-k200y
            r-1cvl2hr
            r-4qtqp9
            r-yyyyoo
            r-5sfk15
            r-dnmrzs
            r-kzbkwu
            r-bnwqim
            r-1plcrui
            r-lrvibr
          "
        >
          <g>
            <path
              d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"
            ></path>
          </g>
        </svg>

        <h1 class="title">此刻正发生(描述文案)</h1>
        <h2 class="little-title">立即开始(描述文案)</h2>

        <div class="form-box">
          <form class="inner" @submit="doSubmit">
            <!-- 登录框 -->
            <div
              v-if="isLogin"
              class="login-account form-item"
              :class="{
                focus: fouceStatus === 'login-account',
                format: !!String(formData.user_name).length,
              }"
            >
              <input
                type="text"
                v-model.trim="formData.user_name"
                @focus="fouceStatus = 'login-account'"
                @blur="fouceStatus = ''"
              />
            </div>
            <!-- 注册框 -->
            <div
              v-else
              class="register-account form-item"
              :class="{
                focus: fouceStatus === 'register-account',
                format: !!String(formData.user_name).length,
              }"
            >
              <input
                type="text"
                v-model.trim="formData.user_name"
                @focus="fouceStatus = 'register-account'"
                @blur="fouceStatus = ''"
              />
            </div>

            <div
              class="password form-item"
              :class="{
                focus: fouceStatus === 'password',
                format: !!String(formData.user_password).length,
              }"
            >
              <input
                type="password"
                v-model="formData.user_password"
                @focus="fouceStatus = 'password'"
                @blur="fouceStatus = ''"
              />
            </div>

            <div
              v-if="!isLogin"
              class="password-confirm form-item"
              :class="{
                focus: fouceStatus === 'password-confirm',
                format: !!String(formData.user_password_confirm).length,
              }"
            >
              <input
                type="password"
                v-model="formData.user_password_confirm"
                @focus="fouceStatus = 'password-confirm'"
                @blur="fouceStatus = ''"
              />
            </div>

            <div
              v-if="!isLogin"
              class="phone form-item"
              :class="{
                focus: fouceStatus === 'phone',
                format: !!String(formData.user_phone).length,
              }"
            >
              <input
                type="tel"
                v-model.number="formData.user_phone"
                @focus="fouceStatus = 'phone'"
                @blur="fouceStatus = ''"
              />
            </div>

            <div
              v-if="!isLogin"
              class="email form-item"
              :class="{
                focus: fouceStatus === 'email',
                format: !!String(formData.user_email).length,
              }"
            >
              <input
                type="tel"
                v-model="formData.user_email"
                @focus="fouceStatus = 'email'"
                @blur="fouceStatus = ''"
              />
            </div>

            <button
              type="submit"
              class="btn"
              :disabled="!submitDisabled"
              :class="{ disable: !submitDisabled }"
            >
              {{ isLogin ? "登录" : "注册" }}
              {{ submitDisabled }}
            </button>

            <p class="tips">
              {{ isLogin ? "还没有" : "已有" }}账号？<a
                href="javascript:void(0)"
                @click="pageType = isLogin ? 'register' : 'login'"
                >前往{{ isLogin ? "注册" : "登录" }}</a
              >
            </p>
            <p class="tips">
              注册/登录即表示同意
              <a href="javascript:void(0)">服务条款</a>
              及
              <a href="javascript:void(0)">隐私政策</a>, 其中包括
              <a href="javascript:void(0)">Cookie使用条款</a>
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userLogin, userRegister } from "@/api/account";

export default {
  name: "login",
  data() {
    return {
      pageType: "login",
      formData: {
        user_name: "",
        user_password: "",
        user_password_confirm: "",
        user_phone: "",
        user_email: "",
      },
      fouceStatus: "",
    };
  },
  watch: {
    pageType() {
      this.formData = {
        user_name: "",
        user_password: "",
        user_password_confirm: "",
        user_phone: "",
        user_email: "",
      };

      this.fouceStatus = "";
    },
  },
  methods: {
    async doSubmit(e) {
      e.preventDefault();
      if (this.submitBtnCheck()) {
        const { user_name, user_password, user_phone, user_email } =
          this.formData;

        let requestData = {};
        let responseData = {};
        if (this.pageType === "login") {
          requestData = { user_name, user_password };
          responseData = await userLogin(requestData);
        } else {
          requestData = { user_name, user_password, user_phone, user_email };
          responseData = await userRegister(requestData);
        }

        if (responseData.code === 200) {
          this.$message.success("登陆成功");
          this.$store.commit("setUserInfo", responseData.data);
          // console.log(this.$store.state.userStore.userInfo);
          this.$router.replace("/home");
        }
      }
    },
    submitBtnCheck() {
      const {
        user_name,
        user_password,
        user_password_confirm,
        user_phone,
        user_email,
      } = this.formData;

      if (!String(user_name).length) {
        this.$message.warning("用户名不能为空");
        return false;
      }

      if (!String(user_password).length) {
        this.$message.warning("密码不能为空");
        return false;
      }

      if (this.pageType === "register") {
        if (user_password !== user_password_confirm) {
          this.$message.warning("两次密码输入不一致");
          return false;
        }

        if (!/^1[2-9]\d{9}$/.test(user_phone)) {
          this.$message.warning("手机号不合法");
          return false;
        }

        if (!/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(user_email)) {
          this.$message.warning("邮箱不合法");
          return false;
        }
      }

      return true;
    },
  },
  computed: {
    submitDisabled() {
      const {
        user_name,
        user_password,
        user_password_confirm,
        user_phone,
        user_email,
      } = this.formData;

      if (!String(user_name).length) {
        return false;
      }

      if (!String(user_password).length) {
        return false;
      }

      if (this.pageType === "register") {
        if (!String(user_password_confirm).length) {
          return false;
        }

        if (!String(user_phone).length) {
          return false;
        }

        if (!String(user_email).length) {
          return false;
        }
      }

      return true;
    },
    isLogin() {
      return this.pageType === "login";
    },
  },
};
</script>

<style lang='scss' scoped>
$textColor: #0f1419;

.banner-box {
  display: flex;
  background-color: #fff;
  height: 100vh;

  .banner {
    flex: 1;
    max-width: 50%;
    // background-image: url("https://abs.twimg.com/sticky/illustrations/lohp_1302x955.png");
    background-color: #241b18;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 64px;

    svg {
      max-height: 380px;
      height: 50%;
      color: #fff;
      fill: currentcolor;
    }
  }

  .main {
    min-width: 45vw;
    padding: 16px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .content {
      min-width: 437px;
      max-width: 760px;
      width: 100%;
      padding: 20px;

      svg {
        color: rgb(29, 155, 240);
        height: 3rem;
        max-width: 100%;
        fill: currentcolor;
        align-self: flex-start;
      }

      .title {
        font-size: 64px;
        line-height: 84px;
        font-weight: 700;
        color: $textColor;
        margin: 48px 0;
      }

      .little-title {
        margin-bottom: 32px;
        line-height: 46px;
        font-size: 31px;
        font-weight: 700;
        color: $textColor;
      }

      .form-box {
        .inner {
          .form-item {
            position: relative;
            max-width: 400px;
            min-width: min-content;
            margin: 12px 0;

            &::after {
              width: calc(100% - 40px);
              position: absolute;
              top: 0;
              left: 20px;
              display: block;
              line-height: 60px;
              font-size: 17px;
              transition: all 200ms;
              z-index: 1;
            }

            &.focus::after {
              line-height: 24px;
              font-size: 14px;
              top: 2px;
              color: rgb(29, 155, 240);
            }

            &.format::after {
              line-height: 24px;
              font-size: 14px;
              top: 2px;
            }

            &.focus input {
              line-height: 30px;
              padding-top: 20px;
              border-color: rgb(29, 155, 240);
              box-shadow: rgb(29, 155, 240) 0 0 0 1px;
              font-size: 17px;
            }

            &.format input {
              line-height: 30px;
              padding-top: 20px;
              font-size: 17px;
            }

            input {
              outline: none;
              width: 100%;
              //   border-radius: 28px;
              border-radius: 5px;
              line-height: 58px;
              border: 1px solid #dadce0;
              color: #3c4043;
              padding: 0 20px;
              box-sizing: border-box;
              background-color: transparent;
              position: relative;
              z-index: 9;
            }
          }

          .login-account {
            &::after {
              content: "手机号码、邮件地址或用户名";
            }
          }

          .register-account {
            &::after {
              content: "用户名";
            }
          }

          .password {
            &::after {
              content: "密码";
            }
          }

          .password-confirm {
            &::after {
              content: "确认密码";
            }
          }

          .email {
            &::after {
              content: "邮箱地址";
            }
          }

          .phone {
            &::after {
              content: "手机号码";
            }
          }

          .btn {
            display: block;
            width: 100%;
            max-width: 400px;
            outline: none;
            font-size: 15px;
            line-height: 42px;
            border-radius: 9999px;
            border: 1px solid rgba($textColor, $alpha: 0);
            background-color: $textColor;
            color: #fff;
            cursor: pointer;

            &.disable {
              background-color: #888a8c;
              color: #fff;
            }
          }

          p.tips {
            margin-top: 12px;
            color: rgb(83, 100, 113);
            font-size: 13px;
            line-height: 16px;
            font-weight: 400;
            max-width: 380px;

            a {
              color: rgb(29, 155, 240);
            }
          }
        }
      }
    }
  }
}
</style>