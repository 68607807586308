var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner-box"},[_vm._m(0),_c('div',{staticClass:"main"},[_c('div',{staticClass:"content"},[_c('svg',{staticClass:"\n          r-k200y\n          r-1cvl2hr\n          r-4qtqp9\n          r-yyyyoo\n          r-5sfk15\n          r-dnmrzs\n          r-kzbkwu\n          r-bnwqim\n          r-1plcrui\n          r-lrvibr\n        ",attrs:{"viewBox":"0 0 24 24","aria-hidden":"true"}},[_c('g',[_c('path',{attrs:{"d":"M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"}})])]),_c('h1',{staticClass:"title"},[_vm._v("此刻正发生(描述文案)")]),_c('h2',{staticClass:"little-title"},[_vm._v("立即开始(描述文案)")]),_c('div',{staticClass:"form-box"},[_c('form',{staticClass:"inner",on:{"submit":_vm.doSubmit}},[(_vm.isLogin)?_c('div',{staticClass:"login-account form-item",class:{
              focus: _vm.fouceStatus === 'login-account',
              format: !!String(_vm.formData.user_name).length,
            }},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.formData.user_name),expression:"formData.user_name",modifiers:{"trim":true}}],attrs:{"type":"text"},domProps:{"value":(_vm.formData.user_name)},on:{"focus":function($event){_vm.fouceStatus = 'login-account'},"blur":[function($event){_vm.fouceStatus = ''},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "user_name", $event.target.value.trim())}}})]):_c('div',{staticClass:"register-account form-item",class:{
              focus: _vm.fouceStatus === 'register-account',
              format: !!String(_vm.formData.user_name).length,
            }},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.formData.user_name),expression:"formData.user_name",modifiers:{"trim":true}}],attrs:{"type":"text"},domProps:{"value":(_vm.formData.user_name)},on:{"focus":function($event){_vm.fouceStatus = 'register-account'},"blur":[function($event){_vm.fouceStatus = ''},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "user_name", $event.target.value.trim())}}})]),_c('div',{staticClass:"password form-item",class:{
              focus: _vm.fouceStatus === 'password',
              format: !!String(_vm.formData.user_password).length,
            }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.user_password),expression:"formData.user_password"}],attrs:{"type":"password"},domProps:{"value":(_vm.formData.user_password)},on:{"focus":function($event){_vm.fouceStatus = 'password'},"blur":function($event){_vm.fouceStatus = ''},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "user_password", $event.target.value)}}})]),(!_vm.isLogin)?_c('div',{staticClass:"password-confirm form-item",class:{
              focus: _vm.fouceStatus === 'password-confirm',
              format: !!String(_vm.formData.user_password_confirm).length,
            }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.user_password_confirm),expression:"formData.user_password_confirm"}],attrs:{"type":"password"},domProps:{"value":(_vm.formData.user_password_confirm)},on:{"focus":function($event){_vm.fouceStatus = 'password-confirm'},"blur":function($event){_vm.fouceStatus = ''},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "user_password_confirm", $event.target.value)}}})]):_vm._e(),(!_vm.isLogin)?_c('div',{staticClass:"phone form-item",class:{
              focus: _vm.fouceStatus === 'phone',
              format: !!String(_vm.formData.user_phone).length,
            }},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.formData.user_phone),expression:"formData.user_phone",modifiers:{"number":true}}],attrs:{"type":"tel"},domProps:{"value":(_vm.formData.user_phone)},on:{"focus":function($event){_vm.fouceStatus = 'phone'},"blur":[function($event){_vm.fouceStatus = ''},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "user_phone", _vm._n($event.target.value))}}})]):_vm._e(),(!_vm.isLogin)?_c('div',{staticClass:"email form-item",class:{
              focus: _vm.fouceStatus === 'email',
              format: !!String(_vm.formData.user_email).length,
            }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.user_email),expression:"formData.user_email"}],attrs:{"type":"tel"},domProps:{"value":(_vm.formData.user_email)},on:{"focus":function($event){_vm.fouceStatus = 'email'},"blur":function($event){_vm.fouceStatus = ''},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "user_email", $event.target.value)}}})]):_vm._e(),_c('button',{staticClass:"btn",class:{ disable: !_vm.submitDisabled },attrs:{"type":"submit","disabled":!_vm.submitDisabled}},[_vm._v(" "+_vm._s(_vm.isLogin ? "登录" : "注册")+" "+_vm._s(_vm.submitDisabled)+" ")]),_c('p',{staticClass:"tips"},[_vm._v(" "+_vm._s(_vm.isLogin ? "还没有" : "已有")+"账号？"),_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.pageType = _vm.isLogin ? 'register' : 'login'}}},[_vm._v("前往"+_vm._s(_vm.isLogin ? "注册" : "登录"))])]),_vm._m(1)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":require("@/assets/logo.jpg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"tips"},[_vm._v(" 注册/登录即表示同意 "),_c('a',{attrs:{"href":"javascript:void(0)"}},[_vm._v("服务条款")]),_vm._v(" 及 "),_c('a',{attrs:{"href":"javascript:void(0)"}},[_vm._v("隐私政策")]),_vm._v(", 其中包括 "),_c('a',{attrs:{"href":"javascript:void(0)"}},[_vm._v("Cookie使用条款")])])}]

export { render, staticRenderFns }